/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {GamesTable} from "../../../modules/adri/widgets/GamesTable";

export const EditGamesPage: React.FC = () => {
  return (
    <div className="row g-0 g-xl-5 g-xxl-8">
      <div className="col-xl-12">
        <GamesTable className={""}/>
      </div>
    </div>
  );
};
