export type IMenuItem = {
  name: string;
  color: string;
  icon: string;
  link: string;
}

export const MenuRoutes: IMenuItem[] = [
  {
    name: 'Home',
    color: 'primary',
    icon: 'fas fa-home',
    link: '/dashboard',
  }, {
    name: 'Historic de Jocs',
    color: 'info',
    icon: 'fas fa-landmark',
    link: '/historic',
  }, {
    name: 'Llistat de Jocs',
    color: 'success',
    icon: 'fas fa-list',
    link: '/games',
  }, {
    name: 'Cementiri de Jocs',
    color: 'dark',
    icon: 'fas fa-skull',
    link: '/graveyard',
  }, {
    name: 'Sign Out',
    color: 'secondary',
    icon: 'fas fa-sign-out-alt',
    link: '/logout',
  }
]
