import React, {lazy, Suspense, useEffect} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {FallbackView} from "../../_start/partials";
import {StartDashboardPage} from "../pages/dashboards/start-dashboard/StartDashboardPage";
import {HistoricPage} from "../pages/dashboards/start-dashboard/HistoricPage";
import {LightDashboardWrapper} from "../pages/dashboards/light-dashboard/LightDashboardWrapper";
import {MenuTestPage} from "../pages/MenuTestPage";
import {Wrap} from "./Wrapper";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../../setup";
import {reloadGames} from "../modules/adri/services/games.service";
import {GraveyardPage} from "../pages/dashboards/start-dashboard/GraveyardPage";
import {EditGamesPage} from "../pages/dashboards/start-dashboard/EditGamesPage";

const enableSplashScreen = () => {
  const splashScreen = document.getElementById("splash-screen");
  if (splashScreen) {
    splashScreen.style.setProperty("display", "flex");
  }
};

const disableSplashScreen = () => {
  const splashScreen = document.getElementById("splash-screen");
  if (splashScreen) {
    splashScreen.style.setProperty("display", "none");
  }
};

export function PrivateRoutes() {
  const dispatch = useDispatch();

  const ProfilePageWrapper = lazy(
    () => import("../modules/profile/ProfilePageWrapper")
  );
  const GeneralPageWrapper = lazy(
    () => import("../modules/general/GeneralPageWrapper")
  );
  const DocsPageWrapper = lazy(() => import("../modules/docs/DocsPageWrapper"));

  const isGamesLoaded = useSelector<RootState>(
    ({ games }) => !games.loading,
    shallowEqual
  );

  useEffect(() => {
    reloadGames();
  }, []);

  useEffect(() => {
    if (isGamesLoaded) {
      disableSplashScreen();
    } else {
      enableSplashScreen();
    }
  }, [isGamesLoaded]);

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={Wrap(StartDashboardPage, 'Home')} />
        <Route path="/historic" component={Wrap(HistoricPage, 'Historic de Jocs')} />
        <Route path="/graveyard" component={Wrap(GraveyardPage, 'Cementiri de Jocs')} />
        <Route path="/games" component={Wrap(EditGamesPage, 'Llistat de Jocs')} />

        <Route path="/light" component={LightDashboardWrapper} />
        <Route path="/general" component={GeneralPageWrapper} />
        <Route path="/profile" component={ProfilePageWrapper} />
        <Route path="/menu-test" component={MenuTestPage} />
        <Route path="/docs" component={DocsPageWrapper} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="dashboard" />
      </Switch>
    </Suspense>
  );
}
