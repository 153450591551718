import React, { useState } from "react";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { MenuModal } from "../../../partials";
import { usePageData, useTheme } from "../../core";
import { MenuInner } from "./MenuInner";
import { Topbar } from "./Topbar";
import {BrowserView, isMobile, MobileView} from 'react-device-detect';


export function Header() {
  const [showMegaMenuModal, setShowMegaMenuModal] = useState(false);
  const { classes, attributes } = useTheme();
  const { pageTitle } = usePageData();

  let logo = toAbsoluteUrl("/media/logos/logo-default.png");
  if (isMobile) {
    logo = toAbsoluteUrl("/media/logos/icon.png");
  }

  return (
    <>
      <div className={`${classes.headerContainer.join(" ")} d-flex align-items-stretch justify-content-between`}
        {...Object.fromEntries(Array.from(attributes.headerContainer))}
      >

        <div className="d-flex align-items-center justify-content-between" style={{flexDirection: 'row', width: '100%'}}>
          <Link to="/">
            <img alt="Logo" src={logo}
              className="h-50px"
            />
          </Link>

          <div className="d-flex align-items-center">
            <h3 className="text-dark fw-bolder my-1 fs-2">{pageTitle}</h3>
          </div>

          <div className="d-flex align-items-center">
            <Topbar />
          </div>
        </div>


        {/*{isSmall && (*/}
        {/*  <div className="d-flex align-items-center">*/}
        {/*    <div className="d-flex align-items-center">*/}
        {/*      <h3 className="text-dark fw-bolder my-1 fs-2">{pageTitle}</h3>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}

        {/*</div>*/}
      </div>

      <MenuModal
        show={showMegaMenuModal}
        handleClose={() => setShowMegaMenuModal(false)}
      >
        <MenuInner />
      </MenuModal>
    </>
  );
}
