import moment from "moment";
import {Config} from "../config";
import {GAME_PLATFORM, IFinishedGame, IGame, IStartedGame} from "./games";
import {IGamesState} from "../modules/adri/redux/GamesRedux";

const customSortGames = <T>(field: 'played' | 'finish', order: 'asc' | 'desc') => {
  return (a: T, b: T) => {
    const first = (order === 'asc') ? a : b;
    const last = (order === 'asc') ? b : a;
    const ret =  first[field].unix() - last[field].unix()
    if (ret !== 0) return ret;
    return first['id'].localeCompare(last['id']);
  }
}

export const parseGamesFromServer = (GamesJson: any): IGamesState => {
  const FinishedGames: IFinishedGame[] = [];
  const CurrentGames: IStartedGame[] = [];
  const AbandonedGames: IStartedGame[] = [];

  for (const game of GamesJson) {
    // Prepare game
    const attrs: Partial<IGame> = {
      id: game._id,
      name: game.name,
      platform: game.platform as GAME_PLATFORM,
      platinum: Boolean(game.platinum),
      finished: game.finish !== undefined,
      description: game.description
    }
    attrs.played = moment.utc(game.played);
    if (game.finish) {
      attrs.finish = moment.utc(game.finish);
    }
    if (game.start) {
      attrs.start = moment.utc(game.start);
    }
    const item = attrs as IGame;

    // Push to specific array
    if (item.finished) {
      FinishedGames.push(item as IFinishedGame);
    } else if (item.played.isBefore(moment.utc().subtract(Config.daysToBeAbandoned, 'days'))) {
      AbandonedGames.push(item as IStartedGame);
    } else {
      CurrentGames.push(item as IStartedGame);
    }
  }


  // SORT
  FinishedGames.sort(customSortGames('finish', 'asc'))
  CurrentGames.sort(customSortGames('played', 'desc'))
  AbandonedGames.sort(customSortGames('played', 'desc'))

  return {
    loading: false,
    finished: FinishedGames,
    current: CurrentGames,
    abandoned: AbandonedGames,
  }
}
