/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {IStartedGame} from "../../../database/games";

type Props = {
  x: number;
  y: number;
  size: number;
  game: IStartedGame;
};

const Tomb: React.FC<Props> = (props) => {
  const lineHeight = (props.game.name.length > 35) ? '1' : '1.15';
  return (
    <div style={{position: 'absolute', top: props.y, left: props.x, width: 200, height: 200, zIndex: props.y + 10, transform: `scale(${props.size})`}}>
      <img src="/media/graveyard/tomb8.png" width={200}/>

      <div className="d-flex align-items-center justify-content-center" style={{
        position: 'absolute',
        left: 76,
        top: 6,
        width: 60,
        height: 40,
        textAlign: 'center',
        transform: 'rotate(3deg)',
      }}>
        <span className="fw-bolder fs-2" style={{color: 'rgb(167, 188, 194)'}}>RIP</span>
      </div>

      <div className="d-flex align-items-center justify-content-center" style={{
        position: 'absolute',
        left: 24,
        top: 41,
        width: 153,
        height: 57,
        textAlign: 'center',
        transform: 'rotate(3deg)',
        lineHeight: lineHeight
      }}>
        <span className="fw-bolder" style={{color: 'rgb(40 40 40)'}}>{props.game.name}</span>
      </div>

      <div className="d-flex align-items-center justify-content-center" style={{
        position: 'absolute',
        left: 74,
        top: 105,
        width: 60,
        height: 40,
        textAlign: 'center',
        transform: 'rotate(3deg)',
      }}>
        <span className="fw-bolder fs-4" style={{color: 'rgb(40 40 40)'}}>{props.game.played.year()}</span>
      </div>
      <div className="d-flex align-items-center justify-content-center" style={{
        position: 'absolute',
        left: 74,
        top: 122,
        width: 60,
        height: 40,
        textAlign: 'center',
        transform: 'rotate(3deg)',
      }}>
        <span className="fw-bolder" style={{color: 'rgb(40 40 40)'}}>{props.game.played.format('DD/MM')}</span>
      </div>

    </div>
  );
};

export { Tomb };
