/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {KTSVG, toAbsoluteUrl} from "../../../../_start/helpers";
import {useSelector} from "react-redux";
import {getAllGames} from "../redux/GamesSelectors";
import {getPlatformColor, getPlatformSvg} from "../utils";
import {GAME_PLATFORM, IGame} from "../../../database/games";
import {filterBySimilarity} from "../text";
import {EditGameWidget} from "../components/EditGameModal";

type Props = {
  className: string;
};

const GamesTable: React.FC<Props> = ({ className }) => {

  const [search, setSearch] = useState("")
  const [gameToEdit, setGameToEdit] = useState<IGame>()

  const allGames = useSelector(getAllGames);
  allGames.sort((a, b) => b.played.unix() - a.played.unix())
  const gamesCount = allGames.length;
  const filteredGames = filterBySimilarity(allGames, search);

  const rows = [];
  for (const game of filteredGames) {

    let color = getPlatformColor(game.platform);
    let size = 'svg-icon-2x';
    if (game.platform === GAME_PLATFORM.GBA) {
      size = 'svg-icon-3x';
    }

    let trophy = null;
    if (game.platinum) {
      trophy = (<i className="fa fa-trophy" style={{marginLeft: 8, color: 'goldenrod'}}></i>);
    }

    rows.push(
      <tr key={"G-"+game.id}>

        <td className="ps-0">
          <div className="symbol symbol-55px me-2 mt-1">
            <span className={`symbol-label bg-light-${color}`} style={{fill: color}}>
              <KTSVG
                path={getPlatformSvg(game.platform)}
                className={size+" text-"+color}
              />
            </span>
          </div>
        </td>

        <td className="ps-0">
          <span className="text-dark fw-bolder text-hover-primary fs-6">{game.name} {trophy}</span>
          <span className="text-muted fw-bold d-block mt-1">{game.description}</span>
        </td>

        <td className="text-end pe-0">
          <span className="text-dark text-hover-primary fs-6">{game.played.format("DD-MM-YYYY")}</span>
        </td>

        <td className="text-end pe-0">
          <button
            type="button"
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm btn-active-light-primary"
            onClick={() => setGameToEdit(game)}
            data-bs-toggle="modal"
            data-bs-target="#edit_game_modal"
          >
            <KTSVG
              path="/media/icons/duotone/Interface/Edit.svg"
              className="svg-icon-4"
            />
          </button>
        </td>
      </tr>
    )
  }

  return (
    <div className={`card ${className}`}>

      <EditGameWidget game={gameToEdit} />

      {/* begin::Header*/}
      <div className="card-header align-items-center border-0 mt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark fs-3">Llistat de tots el Jocs</span>
          <span className="text-muted mt-2 fw-bold fs-6">
            {gamesCount} Jocs
          </span>
        </h3>
        <div className="card-toolbar">
          <input
            type="text"
            className="form-control"
            placeholder="Buscar"
            style={{width: 300}}
            onChange={(e) => {setSearch(e.target.value)}}
          />
        </div>
      </div>
      {/* end::Header*/}

      {/* begin::Body*/}
      <div className="card-body pt-0">
        {/* begin::Table*/}
        <div className="table-responsive">
          <table className="table table-borderless align-middle mb-0">
            <thead>
              <tr>
                <th className="p-0 w-50px"></th>
                <th className="p-0 min-w-150px"></th>
                <th className="p-0 min-w-150px"></th>
                <th className="p-0 min-w-70px"></th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
        {/* end::Table*/}
      </div>
      {/* end: Card Body*/}
    </div>
  );
};

export { GamesTable };
