/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import {IMenuItem} from "../../../routing/routes";

type Props = {
  item: IMenuItem;
}

export const MenuItem: React.FC<Props> = ({item}) => {
  // const pageData = usePageData();
  // const active = (item.name === pageData.pageTitle) ? 'active' : '';
  return (
      <div className="menu-item mx-3" style={{zIndex: 2000}}>
        <Link to={`${item.link}`} data-kt-menu-dismiss="true" className="menu-link px-4 py-3">
          <div className="symbol symbol-35px">
            <span className={`symbol-label bg-light-${item.color}`}>
              <i className={`fas ${item.icon} text-${item.color}`}></i>
            </span>
          </div>
          <div className="ps-4">
            <span className="menu-title fw-bold mb-1">
              {item.name}
            </span>
            {/*<span className="text-muted fw-bold d-block fs-7">5 Hours ago</span>*/}
          </div>
        </Link>
      </div>
  );
}
