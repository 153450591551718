/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {TimelineWidget} from "../../../modules/adri/widgets/TimelineWidget";
import moment from "moment";

export const HistoricPage: React.FC = () => {
  const lastYear = moment().year() - 1;
  const firstYear = 2016;

  const cols = [];
  for (let year = lastYear; year >= firstYear; year--) {
    cols.push(
      <div className="col-xl-4" key={"col-"+year}>
        <TimelineWidget className="card-stretch mb-5 mb-xxl-8" year={year}/>
      </div>
    );
  }

  const rows = [];
  const chunkSize = 3;
  for (let i = 0; i < cols.length; i += chunkSize) {
    const chunk = cols.slice(i, i + chunkSize);
    rows.push(
      <div className="row g-0 g-xl-5 g-xxl-8" key={"h-row-"+i}>
        {chunk}
      </div>
    )
  }

  return (
    <>{rows}</>
  );
};
