import moment from 'moment'
import 'moment/locale/ca'
import {IUser} from "../../../../Server/src/models/user";

moment.locale('ca');

export const DefaultFormat = 'DD/MM/YYYY';

export enum GAME_PLATFORM {
  PlayStation = 'PS',
  Xbox = 'XBOX',
  PC = 'PC',
  Switch = 'SW',
  NDS = 'NDS',
  GBA = 'GBA',
  GameCube = 'GCN',
  IOS = 'IOS',
}

export type IGameBase = {
  id: string;
  name: string;
  played: moment.Moment;
  platform: GAME_PLATFORM;
  platinum: boolean;
  description?: string;
  similarity?: number;
}

export type IFinishedGame = IGameBase & {
  finished: true;
  finish: moment.Moment;
  start?: moment.Moment;
}

export type IStartedGame = IGameBase & {
  finished: false;
  finish: undefined;
  start: moment.Moment;
}

export type IGame = IFinishedGame | IStartedGame;

export type IGames = IGame[];

export interface IGameDB {
  name: string;
  platform: string;
  description?: string;
  start?: Date;
  played: Date;
  finish?: Date;
  platinum?: boolean;
}
