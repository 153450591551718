/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {toAbsoluteUrl} from "../../../../_start/helpers";
import {getFinishedGamesCount} from "../../../database/games.selectors";
import {useSelector} from "react-redux";
import {GamesCount} from "../components/GamesCount";

type Props = {
  className: string;
  innerPadding?: string;
};

const FinishedGamesWidget: React.FC<Props> = ({className,innerPadding = "",}) => {
  const finishedCount = useSelector(getFinishedGamesCount(false));
  const platinumCount = useSelector(getFinishedGamesCount(true));
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className={`card-body pb-0 ${innerPadding}`}>
        {/* begin::Wrapper */}
        <div className="d-flex flex-column h-100">
          {/* begin::Text */}

          {/* end::Text */}


          <div className="d-flex flex-row justify-content-between align-items-center mb-xxl-8 mb-4">
            <h3 className="text-dark text-center fs-1 fw-bolder lh-lg" style={{alignSelf: 'center'}}>
              Jocs Passats
            </h3>
            <GamesCount count={finishedCount + platinumCount} color="#20d489" bgColor="#E4FFF4" icon="fas fa-award"/>
          </div>

          <div className="separator mb-xxl-8 mb-4"></div>

          <div className="d-flex flex-row justify-content-around mb-4">
            <GamesCount count={finishedCount} color="#A1A5B7" bgColor="#F5F8FA" icon="bi bi-trophy" large/>
            <GamesCount count={platinumCount} color="#00e5ff" bgColor="#daffff" icon="bi bi-trophy-fill" large/>
          </div>

          {/* begin::Image */}
          <div
            className="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom card-rounded-bottom h-200px"
            style={{
              backgroundImage: `url('${toAbsoluteUrl(
                "/media/illustrations/process-4.png"
              )}')`,
            }}
          ></div>

          {/* end::Image */}
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { FinishedGamesWidget };
