/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from "react";
import {getCSSVariableValue} from "../../../../_start/assets/ts/_utils";
import ApexCharts, {ApexOptions} from "apexcharts";
import {accumulate, parseToGamesByMonth} from "../transformers";
import {getFinishedGamesByYear} from "../../../database/games.selectors";
import {useSelector} from "react-redux";
import moment from "moment";

export type IAreaComparison = {name: string; data: number[]}[];

type Props = {
  yearA: number;
  yearAName: string;
  yearB: number;
  yearBName: string;
  className?: string;
};

const GamesCompletesComparison: React.FC<Props> = (props) => {
  const chart1Ref = useRef<HTMLDivElement | null>(null);

  const yearAData = parseToGamesByMonth(useSelector(getFinishedGamesByYear(props.yearA)));
  const yearBData = parseToGamesByMonth(useSelector(getFinishedGamesByYear(props.yearB)));

  useEffect(() => {
    if (!chart1Ref.current) {
      return;
    }

    const chart1 = new ApexCharts(chart1Ref.current, getChartOptions(yearAData, yearBData, props));
    if (chart1) {
      chart1.render();
    }

    return () => {
      if (chart1) {
        chart1.destroy();
      }
    };
  }, [chart1Ref]);

  return (
    <div className={`card ${props.className}`}>
      {/* begin::Header */}
      <div
        className={`card-header align-items-center border-0 mt-5`}
      >
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark fs-3">{props.yearBName} vs {props.yearAName}</span>
          <span className="text-muted mt-2 fw-bold fs-6">{props.yearB} vs {props.yearA}</span>
        </h3>
      </div>

      <div className="card-body pt-0 pb-1">
        <div className="d-flex flex-center position-relative">
          <div
            ref={chart1Ref}
            id="comparasion_years_id"
            style={{ width: '100%' }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export { GamesCompletesComparison };

const add10 = (arr: any[]) => arr.reduce((a, b, i) => i === 0 ? [b + 10] : [...a, b + 10], 0);

function getChartOptions(chartDataA: number[], chartDataB: number[], props: Props) {

  chartDataA = [0, ...chartDataA];
  chartDataB = [0, ...chartDataB];
  chartDataA = add10(chartDataA);
  chartDataB = add10(chartDataB);

  const colorPb = '#00E5FF';
  const colorCu = '#20D489';

  const max = chartDataA[chartDataA.length - 1];

  const options: ApexOptions = {
    series: [
      {
        name: props.yearAName + ' - ' + props.yearA,
        data: chartDataA,
      },
      {
        name: props.yearBName + ' - ' + props.yearB,
        data: chartDataB,
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: 250,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: false
    },
    legend: {
      show: true,
      fontSize: '12px',
      floating: true,
      offsetY: 10,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [colorPb, colorCu],
    },
    xaxis: {
      categories: [
        "Inici",
        "Gener",
        "Febrer",
        "Març",
        "Abril",
        "Maig",
        "Juny",
        "Juliol",
        "Agost",
        "Septembre",
        "Octubre",
        "Novembre",
        "Decembre",
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      crosshairs: {
        show: true,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: max,
      labels: {
        show: false,
        style: {
          colors: "#A1A5B7",
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      shared: true,
      y: {
        formatter: function (val) {
          return "" + (val - 10);
        },
      },
    },
    colors: [colorPb, colorCu],
    markers: {
      colors: ['#ffffff'],
      strokeColors: [colorPb, colorCu],
      strokeWidth: 3,
    },
  };
  return options;
}
