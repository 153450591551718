import {Action} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {IFinishedGame, IStartedGame} from "../../../database/games";

export interface ActionWithPayload<T> extends Action {
  payload: T;
} 

const gamesActionTypes = {
  SetGames: "SetGames",
};

const initialGamesState: IGamesState = {
  loading: true,
  finished: [],
  current: [],
  abandoned: [],
};

export interface IGamesState {
  loading: boolean;
  finished: IFinishedGame[];
  current: IStartedGame[];
  abandoned: IStartedGame[];
}

export const GamesReducer = persistReducer(
  { storage, key: "v100-demo1-auth", whitelist: ["user", "accessToken"] },
  (state: IGamesState = initialGamesState, action: ActionWithPayload<IGamesState>) => {
    switch (action.type) {
      case gamesActionTypes.SetGames: {
        return {...state, loading: action.payload.loading, finished: action.payload.finished, current: action.payload.current, abandoned: action.payload.abandoned};
      }
      default:
        return state;
    }
  }
);

export const GamesActions = {
  setGamesState: (newState: IGamesState) => ({type: gamesActionTypes.SetGames, payload: newState}),
};
