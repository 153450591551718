import React from "react";
import {Link} from "react-router-dom";
import {KTSVG} from "../../../helpers";
import {usePageData} from "../../core";
import {MainMenu} from "../../../../app/modules/adri/components/MainMenu";
import {BrowserView, MobileView} from "react-device-detect";
import {MenuRoutes} from "../../../../app/routing/routes";

export function Topbar() {

  const pageData = usePageData();

  let key=0;
  const Links = [];
  for (const route of MenuRoutes) {
    const active = (route.name === pageData.pageTitle) ? 'active' : '';
    Links.push((
      <Link key={`link-${key++}`} to={route.link} className={`btn btn-icon btn-sm btn-light-${route.color} ms-1 ms-lg-6 ${active}`}>
        <i className={`fas ${route.icon}`}></i>
      </Link>
    ))
  }

  return (
    <>

      <BrowserView>
        {Links}
      </BrowserView>


      {/*<div className="ms-1 ms-lg-6">*/}
      {/*  <div*/}
      {/*    className="btn btn-icon btn-sm btn-active-bg-accent"*/}
      {/*    data-kt-menu-trigger="click"*/}
      {/*    data-kt-menu-placement="bottom-end"*/}
      {/*  >*/}
      {/*    <KTSVG*/}
      {/*      path="/media/icons/duotone/General/User.svg"*/}
      {/*      className="svg-icon-1 svg-icon-dark"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <HeaderUserMenu />*/}
      {/*</div>*/}

      <MobileView>
        <div className="ms-1 ms-lg-6">
          <button
            className="btn btn-icon btn-sm btn-light-primary fw-bolder"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            <KTSVG
              path="/media/icons/duotone/Text/Menu.svg"
              className="svg-icon-1"
            />
          </button>
          <MainMenu />
        </div>
      </MobileView>
    </>
  );
}
