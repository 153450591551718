/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {KTSVG} from "../../../../_start/helpers";
import {Config} from "../../../config";
import moment from "moment";
import {getPlatformSvg} from "../utils";
import {GAME_PLATFORM, IStartedGame} from "../../../database/games";
import {useSelector} from "react-redux";
import {getAllCurrentGames} from "../redux/GamesSelectors";
import {editGame, reloadGames} from "../services/games.service";
import {AddGameWidget} from "../components/AddGameModal";
import {ConfirmationModal} from "../components/ConfirmationModal";

type Props = {
  className: string;
  innerPadding?: string;
};

const abandonLimit = moment.utc().subtract(Config.daysToBeAbandoned, 'days').startOf('day');

const CurrentGamesWidget: React.FC<Props> = ({ className, innerPadding = "" }) => {
  const [selectedGame, setSelectedGame] = useState<IStartedGame | undefined>(undefined);

  const currentGames = useSelector(getAllCurrentGames)

  let key = 0;
  const rows = [];
  for (const game of currentGames) {
    const diff = Config.daysToBeAbandoned - game.played.diff(abandonLimit, 'days');
    const abandonPercentage = Math.min(diff * 100 / Config.daysToBeAbandoned, 100);
    let color = 'success';
    let color2 = '#20D489';
    if (abandonPercentage > 33 && abandonPercentage < 67) {
      color = 'warning';
      color2 = '#FFC700';
    } else if (abandonPercentage >= 67) {
      color = 'danger';
      color2 = '#F1416C';
    }
    let size = 'svg-icon-2x';
    if (game.platform === GAME_PLATFORM.GBA) {
      size = 'svg-icon-3x';
    }
    rows.push(
      <tr key={'row-' + (key++)}>
        <th className="px-0 py-3">
          <div className="symbol symbol-65px me-5">
            <span className={`symbol-label bg-light-${color} fill-${color}`}
                  // style={{fill: color2}}
            >
              <KTSVG
                path={getPlatformSvg(game.platform)}
                className={size+" text-"+color}
              />
            </span>
          </div>
        </th>
        <td className="ps-0">
          <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
            {game.name}
          </a>
          <span className="text-muted fw-bold d-block mt-1">
            {game.played.format('DD-MM-YYYY')}
          </span>
        </td>
        <td>
          <div className="d-flex flex-column w-100 me-3">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className="text-dark me-2 fs-6 fw-bolder">
                Abandonament
              </span>
            </div>
            <div className="d-flex align-items-center">
              <div className={"progress h-6px  w-100 bg-light-" + color}>
                <div
                  className={"progress-bar bg-" + color}
                  role="progressbar"
                  style={{ width: abandonPercentage + "%" }}
                  aria-valuenow={abandonPercentage}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              <span className="text-muted fs-7 fw-bold ps-3">
                {abandonPercentage}%
              </span>
            </div>
          </div>
        </td>
        <td className="text-end pe-0">
          <button className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm btn-active-light-primary`}
                  onClick={() => {
                    setSelectedGame(game);
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#confirmation_play_modal">
            <i className="fas fa-play"></i>
          </button>
          <button className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm btn-active-light-primary`}
                  style={{marginLeft: 10}}
                  onClick={() => {
                    setSelectedGame(game);
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#confirmation_finish_modal">
            <i className="fas fa-check"></i>
          </button>
        </td>
      </tr>
    )
  }

  return (
    <div className={`card ${className}`}>
      <AddGameWidget />

      <ConfirmationModal id="confirmation_play_modal" title="Segur que vols continuar el joc?" description={selectedGame?.name} onConfirm={()=>{
        if (selectedGame) {
          editGame(selectedGame?.id, {played: moment.utc().startOf('day').toDate()}).then(reloadGames)
        }
      }} />

      <ConfirmationModal id="confirmation_finish_modal" title="Segur que has acabat el joc?" description={selectedGame?.name} onConfirm={()=>{
        if (selectedGame) {
          const now = moment.utc().startOf('day').toDate();
          editGame(selectedGame?.id, {played: now, finish: now}).then(reloadGames)
        }
      }} />

      {/* <!--begin::Header--> */}
      <div className={`card-header border-0 pt-5 ${innerPadding}`}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-3">
            Jocs Actuals
          </span>
          <span className="text-muted mt-2 fw-bold fs-6">Començats els ultims {Config.daysToBeAbandoned} dies</span>
        </h3>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
          data-bs-toggle="modal"
          data-bs-target="#add_game_modal"
        >
          <KTSVG
            path="/media/icons/duotone/Navigation/Plus.svg"
            className="svg-icon-1"
          />
        </button>
      </div>
      {/* <!--end::Header--> */}

      {/* <!--begin::Body--> */}
      <div className="card-body pt-2 pb-0 mt-n3">
        <div className="tab-content mt-5" id="myTabTables1">
          {/* <!--begin::Tap pane--> */}
          <div
            className="tab-pane fade active show"
            id="kt_tab_pane_1_1"
            role="tabpanel"
            aria-labelledby="kt_tab_pane_1_1"
          >
            {/* <!--begin::Table--> */}
            <div className="table-responsive">
              <table className="table table-borderless align-middle">
                <thead>
                  <tr>
                    <th className="p-0 w-50px"></th>
                    <th className="p-0 min-w-200px"></th>
                    <th className="p-0 min-w-100px"></th>
                    <th className="p-0 min-w-40px"></th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
            </div>
            {/* <!--end::Table--> */}
          </div>
          {/* <!--end::Tap pane--> */}

        </div>
      </div>
    </div>
  );
};

export { CurrentGamesWidget };
