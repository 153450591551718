/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {CurrentGamesWidget} from "../../../modules/adri/widgets/CurrentGamesWidget";
import {FinishedGamesWidget} from "../../../modules/adri/widgets/FinishedGamesWidget";
import {TimelineWidget} from "../../../modules/adri/widgets/TimelineWidget";
import {TopPlatformsWidget} from "../../../modules/adri/widgets/TopPlatformsWidget";
import {getMinMaxYear, getYearsString} from "../../../modules/adri/utils";
import {getBestYear, parseToPlatformsWidget} from "../../../modules/adri/transformers";
import {useSelector} from "react-redux";
import {getAllFinishedGames} from "../../../modules/adri/redux/GamesSelectors";
import {isMobile} from 'react-device-detect';
import {GamesCompletesComparison} from "../../../modules/adri/widgets/GamesCompletesComparison";
import moment from "moment";

export const StartDashboardPage: React.FC = () => {
  const finishedGames = useSelector(getAllFinishedGames);

  if (isMobile) {
    return (
      <>
        <div className="row g-0 g-xl-5 g-xxl-8">
          <div className="col-xl-12">
            <FinishedGamesWidget className="mb-5 mb-xxl-8" />
            <CurrentGamesWidget className="mb-5 mb-xxl-8" />
            <GamesCompletesComparison className="mb-5 mb-xxl-8" yearA={getBestYear(finishedGames)} yearAName={"Millor Any"} yearB={moment().year()} yearBName={"Any Actual"} />
            <TimelineWidget className="mb-5 mb-xxl-8" year={moment().year()}/>
            <TopPlatformsWidget className="mb-5 mb-xxl-8"
                                title="Top 3 Plataformes"
                                subtitle={getYearsString(finishedGames)}
                                pieChartData={parseToPlatformsWidget(finishedGames)}
                                maxPieces={3}
                                minYear={getMinMaxYear().min}
                                maxYear={getMinMaxYear().max}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="row g-0 g-xl-5 g-xxl-8">
          <div className="col-xl-4">
            <FinishedGamesWidget className="mb-5 mb-xxl-8" />
            <TimelineWidget className="mb-5 mb-xxl-8" year={moment().year()}/>
          </div>
          <div className="col-xl-8">
            <CurrentGamesWidget className="mb-5 mb-xxl-8" />
            <GamesCompletesComparison className="mb-5 mb-xxl-8" yearA={getBestYear(finishedGames)} yearAName={"Millor Any"} yearB={moment().year()} yearBName={"Any Actual"} />
            <TopPlatformsWidget className="mb-5 mb-xxl-8"
                                title="Top 3 Plataformes"
                                subtitle={getYearsString(finishedGames)}
                                pieChartData={parseToPlatformsWidget(finishedGames)}
                                maxPieces={3}
                                minYear={getMinMaxYear().min}
                                maxYear={getMinMaxYear().max}
            />
          </div>
        </div>
      </>
    );
  }
};
