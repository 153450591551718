import React from "react";
import {KTSVG} from "../../../../_start/helpers";

type Props = {
  id: string;
  title: string;
  description?: string;
  onConfirm: () => void;
};

export const ConfirmationModal: React.FC<Props> = (props) => {
  return (
    <div className="modal fade" tabIndex={-1} id={props.id}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.title}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotone/Navigation/Close.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">
            <p>{props.description}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={props.onConfirm}>
              Si
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
