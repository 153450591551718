import React, {useState} from "react";
import {KTSVG} from "../../../../_start/helpers";
import {getPlatformName} from "../utils";
import {DefaultFormat, GAME_PLATFORM, IGame, IGameDB} from "../../../database/games";
import {reloadGames, saveGame} from "../services/games.service";
import moment from "moment";

enum GAME_STATE {
  Started = 'Started',
  Finished = 'Finished',
  Platinum = 'Platinum',
}

type Props = {
};

export const AddGameWidget: React.FC<Props> = (props) => {

  let key = 0;
  const defaultDate = moment.utc().startOf('day').format(DefaultFormat);

  const [name, setName] = useState("")
  const [desc, setDesc] = useState("")
  const [date, setDate] = useState(defaultDate)
  const [gameState, setGameState] = useState(GAME_STATE.Started)
  const [platform, setPlatform] = useState(GAME_PLATFORM.PlayStation)

  const onChangeState = (e: any) => {setGameState(e.target.value)}

  const callSaveGame = () => {
    const played = moment.utc(date, DefaultFormat).startOf('day').toDate();
    const game: IGameDB = {
      name: name,
      description: desc,
      platform: platform,
      played: played
    }
    if (gameState === GAME_STATE.Started) {
      game.start = played;
    } else {
      game.finish = played;
      game.platinum = gameState === GAME_STATE.Platinum;
    }
    saveGame(game).then(reloadGames)
  }

  return (
    <div className="modal fade" id="add_game_modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Afegir Joc</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotone/Navigation/Close.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">

            <div className="mb-10">
              <label className="form-label">Nom</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nom"
                onChange={(e) => {setName(e.target.value)}}
              />
            </div>

            <div className="mb-10">
              <label className="form-label">Info Extra</label>
              <input
                type="text"
                className="form-control"
                placeholder="..."
                onChange={(e) => {setDesc(e.target.value)}}
              />
            </div>

            <div className="mb-10">
              <label className="form-label">Data</label>
              <input
                type="text"
                className="form-control"
                placeholder="DD/MM/YYYY"
                defaultValue={defaultDate}
                onChange={(e) => {setDate(e.target.value)}}
              />
            </div>

            <div className="mb-10">
              <label className="form-label">Estat</label>
              <div className="form-check form-check-custom form-check-solid justify-content-around">
                <div>
                  <input className="form-check-input" type="radio" value={GAME_STATE.Started}
                         defaultChecked={true}
                         name="gameState" onChange={onChangeState}/>
                  <label className="form-check-label">Començat</label>
                </div>
                <div>
                  <input className="form-check-input" type="radio" value={GAME_STATE.Finished}
                         onChange={onChangeState}
                         name="gameState" style={{marginLeft: 10}}/>
                  <label className="form-check-label">Acabat</label>
                </div>
                <div>
                  <input className="form-check-input" type="radio" value={GAME_STATE.Platinum}
                         onChange={onChangeState}
                         name="gameState" style={{marginLeft: 10}}/>
                  <label className="form-check-label">Acabat 100%</label>
                </div>
              </div>
            </div>

            <div className="mb-0">
              <label className="form-label">Plataforma</label>
              <select className="form-select" aria-label="Select example" onChange={(e: any) => setPlatform(e.target.value)} >
                {Object.values(GAME_PLATFORM).map((plat) => (
                  <option key={`plt-${key++}`} value={plat}>{getPlatformName(plat)}</option>
                ))}
              </select>
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Tencar
            </button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={callSaveGame}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
