import {RootState} from "../../../../setup";

export const getAllFinishedGames = (state: RootState) => {
  return state.games.finished;
}

export const getAllCurrentGames = (state: RootState) => {
  return state.games.current;
}

export const getAllAbandonedGames = (state: RootState) => {
  return state.games.abandoned;
}

export const getAllGames = (state: RootState) => {
  return [...state.games.current, ...state.games.finished, ...state.games.abandoned];
}
