import axios from "axios";
import {Config} from "../../../config";
import {AuthModel} from "../models/AuthModel";
import {UserModel} from "../models/UserModel";

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post(`${Config.serverUrl}/auth/login`, { username, password });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string
) {
  return axios.post<AuthModel>('ko', {
    email,
    firstname,
    lastname,
    password,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>('ko', { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(`${Config.serverUrl}/auth/me`);
}
