/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {useSelector} from "react-redux";
import {getAllAbandonedGames} from "../redux/GamesSelectors";
import {Tomb} from "../components/Tomb";
import {GamesCount} from "../components/GamesCount";

const SIZE_MULTIPLIERS = [0.7, 1.1];
const ORIGINAL_SIZE = 200;

type ICord = {
  x: number;
  y: number;
}

type IArea = {
  from: ICord;
  to: ICord;
}

const randomCordFromInterval = (maxX: number, maxY: number): ICord => {
  return {
    x: Math.floor(Math.random() * (maxX - 0 + 1)),
    y: Math.floor(Math.random() * (maxY - 0 + 1))
  }
}

const generateInvalidAreas = (cord: ICord, size: number): IArea[] => {
  const xP = 0.85;
  const yP = 0.7;
  const topLeft = {
    from: {
      x: cord.x - (size * xP),
      y: cord.y - (size * yP),
    },
    to: {
      x: cord.x,
      y: cord.y,
    },
  };
  const topRight = {
    from: {
      x: cord.x,
      y: cord.y - (size * yP),
    },
    to: {
      x: cord.x + (size * xP),
      y: cord.y,
    },
  };
  const bottomLeft = {
    from: {
      x: cord.x - (size * xP),
      y: cord.y,
    },
    to: {
      x: cord.x,
      y: cord.y + size,
    },
  };
  const bottomRight = {
    from: {
      x: cord.x,
      y: cord.y,
    },
    to: {
      x: cord.x + (size * xP),
      y: cord.y + size,
    },
  };
  return [topLeft, topRight, bottomLeft, bottomRight];
}

const isValidCord = (cord: ICord, invalidAreas: IArea[]) => {
  for (const area of invalidAreas) {
    if (cord.x >= area.from.x && cord.x < area.to.x && cord.y >= area.from.y && cord.y < area.to.y) return false;
  }
  return true;
}

const getValidCord = (maxX: number, maxY: number, invalidAreas: IArea[]): ICord => {
  let cord = randomCordFromInterval(maxX, maxY);
  while (!isValidCord(cord, invalidAreas)) {
    cord = randomCordFromInterval(maxX, maxY);
  }
  return cord;
}

function calculateSizeMultiplier(percent: number){
  return SIZE_MULTIPLIERS[0] + (percent / 100) * (SIZE_MULTIPLIERS[1] - SIZE_MULTIPLIERS[0]);
}

type Props = {
  className: string;
  size: number;
};

const GraveyardGamesWidget: React.FC<Props> = ({ className, size}) => {

  let allAbandonedGames = useSelector(getAllAbandonedGames)
  const abandonedLength = allAbandonedGames.length;
  allAbandonedGames = allAbandonedGames
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
  const abandonedGames = allAbandonedGames.slice(0, size);

  const fieldWidth = 1240;
  const fieldHeight = 600;

  const maxX = fieldWidth - 200;
  const maxY = fieldHeight - 212;

  let key = 0;
  const tombs = [];

  const invalidAreas: IArea[] = [{
    from: {
      x: 0,
      y: 0,
    },
    to: {
      x: 500,
      y: 35,
    },
  }, {
    from: {
      x: 500,
      y: 0,
    },
    to: {
      x: 1200,
      y: 100,
    },
  }];
  for (const game of abandonedGames) {
    const cord = getValidCord(maxX, maxY, invalidAreas);
    const sizeMultiplier = calculateSizeMultiplier(cord.y / maxY * 100);
    const moreInvalid = generateInvalidAreas(cord, ORIGINAL_SIZE * sizeMultiplier);
    invalidAreas.push(...moreInvalid);
    tombs.push(
      <Tomb key={`tomb-${key++}`} x={cord.x} y={cord.y} game={game} size={sizeMultiplier}/>
    )
  }

  return (
    <div className={`card ${className} mb-5`}>
      {/* <!--begin::Header--> */}
      <div className={`card-header mt-3 align-items-center`}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-3">
            Cementiri de Jocs
          </span>
          <span className="text-muted mt-2 fw-bold fs-6">Sempre seran recordats</span>
        </h3>
        <GamesCount count={abandonedLength} color="#181C32" bgColor="#EFF2F5" icon="fas fas fa-skull" small/>
      </div>

      <div className="card-body" style={{padding: 0, borderBottomLeftRadius: '0.65rem', borderBottomRightRadius: '0.65rem', overflowX: 'auto'}}>
        <div style={{height: fieldHeight, width: fieldWidth}}>
          <div style={{position: 'relative', width: '100%', height: '100%', backgroundImage: 'url(/media/graveyard/windows2.jpg)', backgroundPosition: '0 -100px', backgroundSize: 'cover'}}>
            {tombs}
          </div>
        </div>
      </div>
    </div>
  );
};

export { GraveyardGamesWidget };
