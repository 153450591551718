import React, {useEffect, useState} from "react";
import {KTSVG} from "../../../../_start/helpers";
import {getPlatformName} from "../utils";
import {DefaultFormat, GAME_PLATFORM, IGame, IGameDB} from "../../../database/games";
import {editGame, reloadGames, saveGame} from "../services/games.service";
import moment from "moment";

type Props = {
  game?: IGame
};

export const EditGameWidget: React.FC<Props> = ({game}) => {

  let key = 0;
  const defaultDate = moment.utc().startOf('day').format(DefaultFormat);

  // Init vars
  const [name, setName] = useState("")
  const [desc, setDesc] = useState("")
  const [platinum, setPlatinum] = useState(false)
  const [platform, setPlatform] = useState(GAME_PLATFORM.PlayStation)
  const [start, setStart] = useState(defaultDate)
  const [played, setPlayed] = useState(defaultDate)
  const [finish, setFinish] = useState(defaultDate)

  // Update with selected game
  useEffect(() => {
    if (game) {
      setName(game.name);
      setDesc(game.description || "");
      setStart(game.start?.format(DefaultFormat) || "")
      setPlayed(game.played?.format(DefaultFormat) || "")
      setFinish(game.finish?.format(DefaultFormat) || "")
      setPlatinum(game.platinum);
      setPlatform(game.platform);
    }
  }, [game]);

  // Save game
  const callSaveGame = () => {
    if (game) {
      const editedGame: IGameDB = {
        name: name,
        description: desc,
        platform: platform,
        platinum: platinum,
        played: moment.utc(played, DefaultFormat).startOf('day').toDate(),
      }
      if (start) {
        editedGame.start = moment.utc(start, DefaultFormat).startOf('day').toDate();
      }
      if (finish) {
        editedGame.finish = moment.utc(finish, DefaultFormat).startOf('day').toDate();
      }
      editGame(game.id, editedGame).then(reloadGames)
    }
  }

  return (
    <div className="modal fade" id="edit_game_modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Editar Joc</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotone/Navigation/Close.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">

            <div className="mb-10">
              <label className="form-label">Nom</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nom"
                value={name}
                onChange={(e) => {setName(e.target.value)}}
              />
            </div>

            <div className="mb-10">
              <label className="form-label">Info Extra</label>
              <input
                type="text"
                className="form-control"
                placeholder="..."
                value={desc}
                onChange={(e) => {setDesc(e.target.value)}}
              />
            </div>

            <div className="mb-10">
              <label className="form-label">Start</label>
              <input
                type="text"
                className="form-control"
                placeholder={DefaultFormat}
                value={start}
                onChange={(e) => {setStart(e.target.value)}}
              />
            </div>

            <div className="mb-10">
              <label className="form-label">Played</label>
              <input
                type="text"
                className="form-control"
                placeholder={DefaultFormat}
                value={played}
                onChange={(e) => {setPlayed(e.target.value)}}
              />
            </div>

            <div className="mb-10">
              <label className="form-label">Finish</label>
              <input
                type="text"
                className="form-control"
                placeholder={DefaultFormat}
                value={finish}
                onChange={(e) => {setFinish(e.target.value)}}
              />
            </div>

            <div className="mb-10">
              <label className="form-label">Plataforma</label>
              <select className="form-select" aria-label="Select example" onChange={(e: any) => setPlatform(e.target.value)} value={platform}>
                {Object.values(GAME_PLATFORM).map((plat) => (
                  <option key={`plt-${key++}`} value={plat}>{getPlatformName(plat)}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="form-label">Platinum</label>
              <div className="form-check form-check-custom mb-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={platinum}
                  onChange={(e) => setPlatinum(e.target.checked)}
                />
                <label className="form-check-label">
                  Completat al 100%
                </label>
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Tencar
            </button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={callSaveGame}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
