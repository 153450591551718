import {IFinishedGame, IStartedGame} from "./games";
import {RootState} from "../../setup";
import {getAllFinishedGames} from "../modules/adri/redux/GamesSelectors";

/**
 * Types
 */

type FinishedGamesSelector = (state: RootState) => IFinishedGame[];

type StartedGamesSelector = (state: RootState) => IStartedGame[];

type CountSelector = (state: RootState) => number;

/**
 * Selectors
 */

export const getFinishedGamesByYear = (year: number): FinishedGamesSelector => {
  return state => {
    const finished = getAllFinishedGames(state);
    return finished.filter(item => item.finish.year() === year);
  }
}

export const getFinishedGamesCount = (platinum: boolean): CountSelector => {
  return state => {
    const finished = getAllFinishedGames(state);
    return finished.filter(item => item.platinum === platinum).length;
  }
}
