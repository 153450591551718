/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {GraveyardGamesWidget} from "../../../modules/adri/widgets/GraveyardGamesWidget";

export const GraveyardPage: React.FC = () => {
  return (
    <>
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-12">
          <GraveyardGamesWidget className="mb-5 mb-xxl-8" size={6}/>
        </div>
      </div>
    </>
  );
};
