import {getPlatformColor, getPlatformLabel, getPlatformName} from "./utils";
import {IGames} from "../../database/games";
import {IPiece, IPieChartData} from "./widgets/TopPlatformsWidget";
import {IAreaComparison} from "./widgets/GamesCompletesComparison";

export const parseToPlatformsWidget = (games: IGames): IPieChartData => {
  const gamesByPlatform: Record<string, IPiece> = {};
  for (const game of games) {
    if (!gamesByPlatform[game.platform]) {
      gamesByPlatform[game.platform] = {
        count: 0,
        name: getPlatformName(game.platform),
        label: getPlatformLabel(game.platform),
        color: getPlatformColor(game.platform)
      }
    }
    gamesByPlatform[game.platform].count++;
  }
  return Object.values(gamesByPlatform).sort((a, b) => b.count - a.count)
}

export const getBestYear = (games: IGames): number => {
  const gamesByYear: Record<number, {year: number; completed: number}> = {};
  for (const game of games) {
    if (game.finished) {
      if (!gamesByYear[game.finish.year()]) {
        gamesByYear[game.finish.year()] = {
          year: game.finish.year(),
          completed: 0,
        };
      }
      gamesByYear[game.finish.year()].completed++;
    }
  }
  let bestYear = {
    year: 0,
    completed: 0,
  }
  for (const gameByYear of Object.values(gamesByYear)) {
    if (gameByYear.completed > bestYear.completed) {
      bestYear = gameByYear;
    }
  }
  return bestYear.year;
}

export const accumulate = (arr: any[]) => arr.reduce((a, b, i) => i === 0 ? [b] : [...a, b + a[i - 1]], 0);

export const parseToGamesByMonth = (games: IGames, maxMonth = 11): number[] => {
  const gamesByMonth = {};
  for (let i=0; i<Math.min(12, maxMonth+1); i++) {
    gamesByMonth[i] = 0;
  }
  for (const game of games) {
    if (game.finished && game.finish.month() <= maxMonth) {
      gamesByMonth[game.finish.month()]++;
    }
  }
  return accumulate(Object.values(gamesByMonth));
}

export const parseToYearsWidget = (games: IGames): IPieChartData => {
  let colorsIndex = 0;
  const colors = ['primary', 'success', 'info', 'primary', 'primary', 'primary', 'primary', 'primary'];
  const gamesByYear: Record<string, IPiece> = {};
  for (const game of games) {
    if (!gamesByYear[game.played.year()]) {
      gamesByYear[game.played.year()] = {
        count: 0,
        name: game.played.year().toString(),
        label: game.played.year().toString(),
        color: 'primary'
      }
      colorsIndex++;
    }
    gamesByYear[game.played.year()].count++;
  }
  return Object.values(gamesByYear).sort((a, b) => b.count - a.count)
}
