/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {getPlatformColor} from "../../../../app/modules/adri/utils";
import {useSelector} from "react-redux";
import {getFinishedGamesByYear} from "../../../database/games.selectors";

type Props = {
  year: number;
  className: string;
};

const TimelineWidget: React.FC<Props> = ({ className, year }) => {

  const list = useSelector(getFinishedGamesByYear(year));

  let key = 0;
  const items = [];
  let lastMonth = -1;
  for (const game of list) {
    if (game.finish.month() > lastMonth) {
      items.push(
        <div key={'item-' + (key++)} className="timeline-item" style={{alignItems: 'center'}}>
          <div className="fw-bolder text-gray-800" style={{textTransform: 'capitalize'}}>
            {game.finish.format('MMMM')}
          </div>
        </div>
      );
      lastMonth = game.finish.month();
    }
    let trophy = null;
    if (game.platinum) {
      trophy = (<i className="fa fa-trophy" style={{marginLeft: 8, color: 'goldenrod'}}></i>);
    }
    items.push(
      <div key={'item-' + (key++)} className="timeline-item" style={{alignItems: 'center'}}>
        <div className="timeline-label text-gray-800 fs-6" style={{textAlign: 'center'}}>
          {game.finish.format('DD')}

        </div>
        <div className="timeline-badge">
          <i className="fa fa-genderless fs-1" style={{color: getPlatformColor(game.platform)}}></i>
        </div>
        <div className="timeline-content d-flex">
          <span className="fw-bolder text-gray-800 ps-3">{game.name}{trophy}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={`card ${className}`}>
      <div className="card-header align-items-center border-0 mt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark fs-3">{year}</span>
          <span className="text-muted mt-2 fw-bold fs-6">
            Jocs passats
          </span>
        </h3>
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark fs-3">{list.length}</span>
        </h3>
      </div>


      <div className="card-body pt-3">
        <div className="timeline-label">
          {items}
        </div>
      </div>

    </div>
  );
};

export { TimelineWidget };
