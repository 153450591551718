import {parseGamesFromServer} from "../../../database/games.parser";
import {GamesActions} from "../redux/GamesRedux";
import store from "../../../../setup/redux/Store";
import axios, {AxiosResponse} from "axios";
import {Config} from "../../../config";
import * as auth from "../../auth/redux/AuthRedux";
import {IGame, IGameDB} from "../../../database/games";

const GenericServiceCatch = (error: any) => {
  if (error && error.response && error.response.status === 401) {
    store.dispatch(auth.actions.logout())
  } else {
    console.error("Axios Error:", error);
  }
}

export function getGames() {
  return axios.get(`${Config.serverUrl}/games`).catch(GenericServiceCatch) as Promise<AxiosResponse>;
}

export function saveGame(game: IGameDB) {
  return axios.post(`${Config.serverUrl}/games`, game).catch(GenericServiceCatch) as Promise<AxiosResponse>;
}

export function editGame(id: string, game: Partial<IGameDB>) {
  return axios.put(`${Config.serverUrl}/games/${id}`, game).catch(GenericServiceCatch) as Promise<AxiosResponse>;
}

export const reloadGames = () => {
  getGames().then(({data}) => {
    const payload = parseGamesFromServer(data);
    store.dispatch(GamesActions.setGamesState(payload));
  });
}
