import {getConfig, IThemeConfig, PageTitle, useTheme} from "../../_start/layout/core";
import React, {useEffect} from "react";

const defaultPageConfig = getConfig();
const dashboardPageConfig: Partial<IThemeConfig> = {
  aside: {
    ...defaultPageConfig.aside,
    display: true,
    primaryDisplay: false,
    secondaryDisplay: true,
    toggle: false,
    content: "menu",
  },
  toolbar: {
    ...defaultPageConfig.toolbar,
    display: false,
  },
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: false,
  },
};

export const Wrap = (Component: React.FC, title: string) => {
  return function HistoricPageWrapper() {
    const { setTheme } = useTheme();
    // Refresh UI after config updates
    useEffect(() => {
      setTheme(dashboardPageConfig);
      return () => {
        setTheme(defaultPageConfig);
      };
    }, []);

    return (
      <>
        <Component />
        <PageTitle>{title}</PageTitle>
      </>
    );
  }

}
