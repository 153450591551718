/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

type Props = {
  count: number;
  color: string;
  bgColor: string;
  icon: string;
  border?: boolean;
  large?: boolean;
  small?: boolean;
};

const GamesCount: React.FC<Props> = (props) => {
  // const hex = props.color === 'warning' ? '#00c5ff' : '#A1A5B7';
  // const hexBg = props.color === 'warning' ? '#c3ffff' : '#F5F8FA';
  let style: any = {backgroundColor: props.bgColor};
  if (props.border) style = {border: '2px solid ' + props.color}
  let size = '50';
  let fontSize = '2rem';
  if (props.small) {
    size = '40';
    fontSize = '1.5rem';
  }
  if (props.large) size = '60';
  return (
    <div className={`card card-stretch`} style={style}>
      <div className="d-flex card-body" style={{padding: 0, paddingRight: 15}}>
        <div className="d-flex align-items-center">
          <div className={`symbol symbol-${size}px me-5`}>
          <span className={`symbol-label`} style={{backgroundColor: props.bgColor}}>
            <i className={props.icon} style={{color: props.color, fontSize}}></i>
          </span>
          </div>
          <div>
            <span className="text-gray-800 fw-bolder" style={{fontSize}}>
              {props.count}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { GamesCount };
