import {GAME_PLATFORM, IFinishedGame, IGames} from "../../database/games";
import moment from "moment";

export const getPlatformColor = (platform: GAME_PLATFORM) => {
  switch (platform) {
    case GAME_PLATFORM.Switch:
      return '#e60012';
    case GAME_PLATFORM.PlayStation:
      return '#00B2FF';
    case GAME_PLATFORM.PC:
      return '#1b2838';
    case GAME_PLATFORM.GameCube:
    case GAME_PLATFORM.NDS:
    case GAME_PLATFORM.GBA:
      return '#9483d5';
    case GAME_PLATFORM.IOS:
      return '#363636';
    case GAME_PLATFORM.Xbox:
      return '#9bc848';
  }
}

export const getPlatformLabel = (platform: GAME_PLATFORM) => {
  return platform.toString();
}

export const getPlatformSvg = (platform: GAME_PLATFORM) => {
  switch (platform) {
    case GAME_PLATFORM.Switch:
      return '/media/svg/games/sw.svg';
    case GAME_PLATFORM.PlayStation:
      return '/media/svg/games/ps.svg';
    case GAME_PLATFORM.PC:
      return '/media/svg/games/pc.svg';
    case GAME_PLATFORM.GameCube:
      return '/media/svg/games/gcn.svg';
    case GAME_PLATFORM.GBA:
      return '/media/svg/games/gba.svg';
    case GAME_PLATFORM.NDS:
      return '/media/svg/games/nds.svg';
    case GAME_PLATFORM.IOS:
      return '/media/svg/games/ios.svg';
    case GAME_PLATFORM.Xbox:
      return '/media/svg/games/xbox.svg';
  }
}

export const getPlatformName = (platform: GAME_PLATFORM) => {
  switch (platform) {
    case GAME_PLATFORM.Switch:
      return 'Switch';
    case GAME_PLATFORM.PlayStation:
      return 'PlayStation';
    case GAME_PLATFORM.PC:
      return 'PC';
    case GAME_PLATFORM.GameCube:
      return 'GameCube';
    case GAME_PLATFORM.NDS:
      return 'Nintendo DS';
    case GAME_PLATFORM.GBA:
      return 'GameBoy Advance';
    case GAME_PLATFORM.IOS:
      return 'iOS';
    case GAME_PLATFORM.Xbox:
      return 'Xbox';
  }
}

export const getMinMaxYear = () => {
  return {
    min: 2016,
    max: moment().year(),
  }
}

export const getYearsString = (games: IFinishedGame[]) => {
  let yearStr = '';
  if (games.length) {
    const first = games[0].finish.year();
    const last = games[games.length-1].finish.year();
    yearStr += first;
    if (first !== last) {
      yearStr += ' - ' + last
    }
  }
  return yearStr;
}
