/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import Chart, {ChartConfiguration} from "chart.js";
import {YearDropdown} from "../../../../app/modules/adri/components/YearDropdown";
import {getCSSVariableValue} from "../../../../_start/assets/ts/_utils";
import {KTSVG, toAbsoluteUrl} from "../../../../_start/helpers";

export type IPiece = {name: string; label: string; count: number; color: string};

export type IPieChartData = IPiece[];

type Props = {
  minYear: number;
  maxYear: number;
  title: string;
  subtitle?: string;
  className: string;
  innerPadding?: string;
  pieChartData: IPieChartData;
  maxPieces?: number;
  onYearChange?: (year: number) => void;
};

const TopPlatformsWidget: React.FC<Props> = ({ minYear, maxYear, title, subtitle, className, innerPadding = "", maxPieces = 100, pieChartData, onYearChange }) => {
  let total = 0;
  for (let i=0; i<Math.min(maxPieces, pieChartData.length) ; i++) {
    total += pieChartData[i].count;
  }

  let index = 0;
  let legend = [];
  const legends = [];
  let totalLegends = 0;
  let totalPercentage = 0;
  for (const data of pieChartData) {
    let percentage = 0;
    if (legend.length + 1 === maxPieces) {
      percentage = 100 - totalPercentage;
    } else {
      percentage = Math.round(data.count/total*100);
      totalPercentage += percentage;
    }
    if (totalLegends < maxPieces) {
      totalLegends++;
      legend.push(
        <div key={"leg-"+(index++)}>
          <span className="fw-bolder text-gray-800">{percentage}% {data.label}</span>
          <span className="w-25px h-5px d-block rounded mt-1" style={{backgroundColor: data.color}}></span>
        </div>
      )
    }
    if (legend.length === 4) {
      legends.push(<div className="d-flex justify-content-around pt-5" key={"leg-"+(index++)}>{legend}</div>)
      legend = [];
    }
  }
  if (legend.length > 0) {
    legends.push(<div className="d-flex justify-content-around pt-5" key={"leg-"+(index++)}>{legend}</div>)
  }

  useEffect(() => {
    const element = document.getElementById(
      "kt_stats_widget_1_chart"
    ) as HTMLCanvasElement;
    if (!element) {
      return;
    }

    const options = getChartOptions(pieChartData, maxPieces);
    const ctx = element.getContext("2d");
    let myDoughnut: Chart | null;
    if (ctx) {
      myDoughnut = new Chart(ctx, options);
    }
    return function cleanUp() {
      if (myDoughnut) {
        myDoughnut.destroy();
      }
    };
  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div
        className={`card-header align-items-center border-0 mt-5 ${innerPadding}`}
      >
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark fs-3">{title}</span>
          <span className="text-muted mt-2 fw-bold fs-6">{subtitle}</span>
        </h3>
        <div className="card-toolbar">
          {/* begin::Dropdown */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTSVG
              path="/media/icons/duotone/Layout/Layout-4-blocks-2.svg"
              className="svg-icon-1"
            />
          </button>
          <YearDropdown min={minYear} max={maxYear} onYearChange={(y) => {if (onYearChange) onYearChange(y);}}/>
          {/* end::Dropdown */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body pt-12">

        <div className="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center h-175px pb-18"
          style={{
            backgroundImage: `url('${toAbsoluteUrl(
              "/media/svg/illustrations/bg-1.svg"
            )}')`,
          }}
        >
          <div className="fw-bolder fs-1 text-gray-800 position-absolute">
            {total}
          </div>
          <canvas id="kt_stats_widget_1_chart"></canvas>
        </div>

        {legends}

      </div>
    </div>
  );
};

export { TopPlatformsWidget };

function getChartOptions(pieChartData: IPieChartData, maxPieces: number) {
  const tooltipBgColor = getCSSVariableValue("--bs-gray-200");
  const tooltipColor = getCSSVariableValue("--bs-gray-800");

  const values = [];
  const colors = [];
  const labels = [];
  for (const piece of pieChartData) {
    if (values.length < maxPieces) {
      values.push(piece.count);
      colors.push(piece.color);
      labels.push(piece.name);
    }
  }

  const options: ChartConfiguration = {
    type: "doughnut",
    data: {
      datasets: [
        {
          data: values,
          backgroundColor: colors,
        },
      ],
      labels: labels,
    },
    options: {
      cutoutPercentage: 75,
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Technology",
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      tooltips: {
        enabled: true,
        intersect: false,
        mode: "nearest",
        bodySpacing: 5,
        yPadding: 10,
        xPadding: 10,
        caretPadding: 0,
        displayColors: false,
        backgroundColor: tooltipBgColor,
        bodyFontColor: tooltipColor,
        cornerRadius: 4,
        footerSpacing: 0,
        titleSpacing: 0,
      },
    },
  };
  return options;
}

// function randomScalingFactor() {
//   return Math.round(Math.random() * 100);
// }
