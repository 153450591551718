import React from "react";
import {MenuItem} from "./MenuItem";
import {MenuRoutes} from "../../../routing/routes";
import {Link} from "react-router-dom";
import {usePageData} from "../../../../_start/layout/core";

export function MainMenu() {

  // const pageData = usePageData();

  let key=0;
  const Links = [];
  for (const route of MenuRoutes) {
    if (route.link === '/logout') continue;
    Links.push((<MenuItem key={`item-menu-${key++}`} item={route}/>));
  }

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-rounded fw-bold menu-title-gray-800 menu-hover-bg"
      data-kt-menu="true"
    >
      <div className="menu-item mx-3">
        <div className="menu-content fs-6 text-dark fw-bolder py-6">
          My Games
        </div>
      </div>

      <div className="separator mb-3"></div>

      {Links}

      <div className="separator mt-3"></div>

      <div className="menu-item mx-2 text-center">
        <div className="menu-content py-5">
          <a href="/logout" className="btn btn-primary fw-bolder me-2 px-5">
            <i className="fas fa-sign-out-alt"></i>
            Sign Out
          </a>
        </div>
      </div>
    </div>
  );
}
